<template>
    <div>
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="shadow rounded-lg p-3 mt-4" v-for="(item, index) in 10" :key="index+'chat'">
                <img src="http://balangankab.go.id/icon.png" style="width:40px;height:40px;" 
                class="rounded-circle float-right" alt="">
                <p class="p-2">Nama</p>
                <hr>
                <div class="text-justify p-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur voluptatibus amet, porro animi ad minima rerum magni ea eligendi ab ipsam, enim eveniet. Doloremque illo distinctio eum voluptates, voluptatem cumque.
                </div>
            </div>
          </div>
        </div>
     <div class="d-block d-sm-none"><br><br><br><br></div>
    </div>
</template>
<script>
export default{
    layout:'app_shop_admin'
}
</script>